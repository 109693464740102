<div
  class="filter-group tw-relative tw-mb-4"
  [ngClass]="{
    'empty-group': isEmptyGroup(), 
    'tw-bg-white': isEvenDepth(), 
    'tw-bg-gray-100': !isEvenDepth(),
    'tw-pt-6': group.isJobsGroup,
    'tw-p-4 tw-pb-3': isEmptyGroup() && group.isJobsGroup }">
  <!-- editable group name -->
  <h4
    *ngIf="group.isJobsGroup"
    class="tw-absolute tw-top-[-13px] tw-left-[20px] tw-bg-white tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-flex tw-items-center tw-text-[13px] tw-font-semibold tw-m-0 tw-pl-[10px]">
    <button
      *ngIf="!editingGroupName"
      (click)="toggleEdit()"
      class="tw-w-6 tw-h-6 tw-text-gray-700 tw-rounded tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-bg-gray-200 focus:tw-border focus:tw-border-solid focus:tw-bg-gray-200">
      <mat-icon class="tw-text-[16px] tw-h-[17px] tw-leading-none tw-overflow-visible">
        edit
      </mat-icon>
    </button>

    <span
      *ngIf="!editingGroupName"
      (click)="toggleEdit()"
      class="tw-text-sm tw-text-gray-700 tw-cursor-pointer tw-mx-1">
      {{ group.name }}
    </span>

    <input
      *ngIf="editingGroupName"
      matInput
      [(ngModel)]="group.name"
      (blur)="saveGroupName()"
      (keydown.enter)="saveGroupName()"
      #groupNameInput
      class="tw-text-sm tw-border-1 tw-border-dashed tw-border-gray-300 tw-bg-gray-100 tw-mx-1 tw-my-1.5 tw-pl-1" />

    <button
      matTooltip="Only match people where a job meets all of these criteria. Multiple job groups can be added."
      matTooltipPosition="above"
      class="tw-rounded focus:tw-border focus:tw-border-solid focus:tw-bg-gray-200 tw-mr-1">
      <mat-icon
        class="tw-text-[18px] tw-w-[18px] tw-h-[18px] tw-text-gray-700 tw-leading-5 tw-cursor-pointer">
        info_outline
      </mat-icon>
    </button>
  </h4>

  <div
    *ngIf="group.isJobsGroup"
    class="tw-flex tw-flex-wrap tw-justify-between xl:tw-justify-start tw-gap-4 xl:tw-gap-8 tw-mb-5 tw-mt-2">
    <div>
      <mat-label class="tw-text-xs tw-font-semibold tw-mr-1">Job Status:</mat-label>
      <mat-button-toggle-group
        name="jobMatch"
        aria-label="Job Match"
        [(ngModel)]="group.jobsGroupType"
        class="filter-group-btn-toggle tw-text-xs">
        <mat-button-toggle *ngFor="let opt of matchJobsOptions" [value]="opt.value">
          {{opt.displayValue}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div>
      <mat-label class="tw-text-xs tw-font-semibold tw-mr-1">Position Status</mat-label>
      <mat-button-toggle-group
        name="tenureType"
        aria-label="Tenure Type"
        [(ngModel)]="group.positionStatus"
        class="filter-group-btn-toggle tw-text-xs">
        <mat-button-toggle *ngFor="let opt of tenureOptions" [value]="opt.value">
          {{opt.displayValue}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <!-- group operator change  control -->
  <mat-form-field
    *ngIf="!group.isJobsGroup"
    appearance="outline"
    class="filter-operator-dropdown tw-absolute tw-left-[9px] tw-top-[45px] tw-z-10"
    [ngClass]="{'tw-hidden': isEmptyGroup()}">
    <mat-select [(value)]="group.operator" aria-label="Select filter group operator">
      <mat-option value="and">and</mat-option>
      <mat-option value="or">or</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="group-body">
    <ng-container *ngFor="let item of group?.filters; let i = index">
      <!-- Render SimpleFilterComponent if item is a SearchFilter -->
      <app-simple-filter
        *ngIf="isSimpleFilter(item)"
        [filter]="item"
        (removeFilter)="onRemoveItem(i)"
        [operator]="group.operator"
        [depth]="depth">
      </app-simple-filter>

      <app-filter-company-search
        *ngIf="isCompanySearchFilter(item)"
        [filter]="item"
        (removeFilter)="onRemoveItem(i)"
        [operator]="group.operator"
        [depth]="depth">
      </app-filter-company-search>

      <!-- Render FilterGroupComponent if item is a FilterGroup -->
      <app-filter-group
        *ngIf="isFilterGroup(item)"
        [group]="item"
        [isRoot]="false"
        [depth]="depth + 1"
        [parentOperator]="group.operator"
        [parentGroup]="group"
        (filterAdded)="filterAdded.emit($event)"
        (chartAdded)="chartAdded.emit($event)"
        (removeGroup)="onRemoveItem(i)">
      </app-filter-group>
    </ng-container>
  </div>

  <!-- control btns group -->
  <div
    class="group-footer filter-row query-item tw-flex tw-relative"
    [ngClass]="{'tw-ml-8': !isEmptyGroup()}">
    <span class="field-wrapper tw-isolate tw-inline-flex tw-rounded tw-shadow-sm">
      <button
        (click)="addFilter()"
        type="button"
        class="tw-relative tw-inline-flex tw-items-center tw-rounded-l tw-bg-white tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-primary_purple-600 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10">
        <mat-icon class="tw-text-[20px] tw-leading-6">add</mat-icon>
        <span *ngIf="isRoot">Add Filter</span>
      </button>
      <button
        *ngIf="!group.isJobsGroup"
        (click)="addGroup()"
        type="button"
        class="tw-relative -tw-ml-px tw-inline-flex tw-items-center tw-bg-white tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-primary_purple-600 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
        [ngClass]="{'tw-rounded-r': !group.isJobsGroup && !isRoot}">
        <mat-icon class="tw-text-[20px] tw-leading-[25px]">playlist_add</mat-icon>
        <span *ngIf="isRoot">Add Filter Group</span>
      </button>
      <button
        *ngIf="group.isJobsGroup || isRoot"
        (click)="addChart()"
        type="button"
        class="tw-relative -tw-ml-px tw-inline-flex tw-items-center tw-rounded-r tw-bg-white tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-primary_purple-600 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10">
        <mat-icon class="tw-text-[20px] tw-leading-[25px]">insights</mat-icon>
        <span *ngIf="isRoot">Add Chart</span>
      </button>
    </span>
  </div>

  <button
    *ngIf="!isRoot"
    class="group-delete icon-btn-hover tw-absolute  tw-border tw-border-gray-200 tw-border-solid hover:tw-border-gray-400 tw-bg-white"
    [ngClass]="{
      'tw-bottom-1 tw-right-1': isEmptyGroup() && !group.isJobsGroup,
  'tw-bottom-3 tw-right-3': !isEmptyGroup() || (isEmptyGroup() && group.isJobsGroup) }"
    (click)="onDeleteGroup()">
    <mat-icon class="tw-text-gray-600">delete_outline</mat-icon>
  </button>

  <!-- operator group indicator -->
  <div
    class="connector-and connector-and-group"
    [ngClass]="{'tw-bg-white': !isEvenDepth(), 'tw-bg-gray-100': isEvenDepth(), 'tw-text-gray-500': isEvenDepth(), 'tw-hidden': isRoot}">
    {{ parentOperator || 'and' }}
  </div>

  <!-- selected charts inside filter groups -->
  <div
    *ngIf="group.fieldsToChart?.length > 0 && !isRoot"
    class="tw-flex tw-flex-wrap tw-gap-1 tw-items-center tw-mr-8 tw-mt-4">
    <div class="tw-font-semibold tw-mr-2">Selected Charts:</div>
    <ng-container *ngFor="let chart of group.fieldsToChart">
      <div class="badge-selected-items tw-flex tw-items-center tw-gap-2 tw-rounded-md tw-pl-3">
        <span class="tw-font-semibold">{{ chart.columnName }}</span>
        <button
          class="tw-text-white tw-leading-3 tw-flex tw-items-center hover:tw-bg-primary_purple-800 tw-transition-all">
          <mat-icon
            class="tw-text-[18px] tw-w-[18px] tw-h-[18px]"
            (click)="removeChart(chart.columnName); $event.stopPropagation()">
            close
          </mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<!-- selected charts for root group -->
<div
  *ngIf="isRoot && group.fieldsToChart?.length > 0"
  class="tw-flex tw-flex-wrap tw-gap-1 tw-items-center tw-mr-8 tw-ml-8 tw-mb-1">
  <div class="tw-font-semibold tw-mr-2">Selected Charts:</div>
  <ng-container *ngFor="let chart of group.fieldsToChart">
    <div class="badge-selected-items tw-flex tw-items-center tw-gap-2 tw-rounded-md tw-pl-3">
      <span class="tw-font-semibold">{{ chart.columnName }}</span>
      <button
        class="tw-text-white tw-leading-3 tw-flex tw-items-center hover:tw-bg-primary_purple-800 tw-transition-all">
        <mat-icon
          class="tw-text-[18px] tw-w-[18px] tw-h-[18px]"
          (click)="removeChart(chart.columnName); $event.stopPropagation()">
          close
        </mat-icon>
      </button>
    </div>
  </ng-container>
</div>
