import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  GridReadyEvent,
  ITextFilterParams,
  SideBarDef,
  ValueGetterParams,
} from 'ag-grid-community';
import { Contact, Ledger, LedgerContactsService } from 'ldt-ledger-service-api';
import { forkJoin, Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import {
  Contact as ContactV2,
  GetLedgerContacts200Response,
  LedgerContactsService as LedgerContactsServiceV2,
} from 'ldt-ledger-service-api-v2';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import {
  SearchFilter,
  SearchPersonsData200Response,
  SearchPersonsDataRequestData,
  SearchService,
} from 'ldt-dw-reader-service-api';
import * as moment from 'moment';
import { JobsTableCellRendererComponent } from 'src/app/shared/jobs-table-cell-renderer/jobs-table-cell-renderer.component';

@Component({
  selector: 'app-v2-audit',
  templateUrl: './v2-audit.component.html',
  styleUrls: ['./v2-audit.component.scss'],
})
export class V2AuditComponent implements OnInit {
  ledger: Ledger;
  orgId: string;

  rowData: any[];
  rowsToEval: number = 5000;

  foundInV2: number;
  matchingData: number;

  NinetyDaysAgo = moment().subtract(90, 'days');

  constructor(
    private ledgerService: LedgerContactsService,
    private ledgerServiceV2: LedgerContactsServiceV2,
    private route: ActivatedRoute,
    private auth: AuthService,
    private dwSearch: SearchService,
    private notify: NotificationService
  ) {}
  ngOnInit(): void {
    // Get the ledger from the resolver and org ID from the route
    this.ledger = this.route.parent?.snapshot.data.userdata;
    let routeOrg = this.route.parent?.snapshot.paramMap.get('orgId');
    if (routeOrg) {
      this.orgId = routeOrg;
    }

    forkJoin([this.getV1Contacts(), this.getV2Contacts()]).subscribe({
      next: (r) => {
        let v1c = r[0].contacts || [];
        let v2c = r[1].contacts || ([] as ContactV2[]);

        this.getDWPeople(v2c.map((c) => c.linkedin || '')).subscribe({
          next: (res: SearchPersonsData200Response) => {
            const ppl = res.results?.hits?.hits?.map((h: any) => h._source);
            const mergedData =
              r[0].contacts?.map((c, i) => {
                const rd = {
                  id: c.id,
                  v1Data: this.flattenObject(c),
                  v2Data: this.flattenObject(v2c.find((v2) => v2.id === c.id)),
                  dwData: ppl?.find((p) => p.linkedin === c.linkedin) || {},
                };
                return rd;
              }) || [];

            this.rowData =
              mergedData.filter((r: any) => {
                const inV2 = 'id' in r.v2Data;
                const sameCo =
                  r.v1Data['company']?.toLowerCase() ===
                  r.v2Data['current_position.company.name']?.toLowerCase();
                return inV2 && !sameCo;
              }) || [];

            this.foundInV2 = mergedData.filter((r: any) => 'id' in r.v2Data).length;
            this.matchingData = mergedData.filter((r: any) =>
              this.compareCompanies(r.v1Data['company'], r.v2Data['current_position.company.name'])
            ).length;
          },
          error: () => {
            this.notify.error('error searching DW for people');
          },
        });
      },
      error: () => {
        this.notify.error('error getting ledger contacts');
      },
    });
  }

  compareCompanies(name1: string, name2: string): boolean {
    if (name1 === name2) return true;

    if (!name1 || !name2) return false;

    if (name1.toLowerCase() === name2.toLowerCase()) return true;

    if (name1.startsWith(name2) || name2.startsWith(name1)) return true;

    name1 = name1.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    name2 = name2.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

    if (name1 === name2) return true;

    if (name1.startsWith(name2) || name2.startsWith(name1)) return true;

    return false;
  }

  getV1Contacts() {
    return this.ledgerService.getLedgerContacts(
      this.ledger.id,
      this.orgId,
      undefined,
      undefined,
      undefined,
      'id',
      this.rowsToEval
    );
  }

  getV2Contacts(): Observable<GetLedgerContacts200Response> {
    return this.ledgerServiceV2.getLedgerContacts(
      this.orgId,
      this.ledger.id,
      'id',
      this.rowsToEval
    );
  }

  getDWPeople(lipids: string[]) {
    let filter: SearchFilter = {
      field: 'linkedin',
      type: SearchFilter.TypeEnum.Must,
      match_type: SearchFilter.MatchTypeEnum.Exact,
      string_values: lipids,
    };

    let searchRequest: SearchPersonsDataRequestData = {
      filters: [filter],
      size: lipids.length,
    };
    return this.dwSearch.searchPersonsData(undefined, searchRequest);
  }

  flattenObject(ob: any) {
    var toReturn: any = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (typeof ob[i] == 'object' && ob[i] !== null) {
        var flatObject = this.flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }

  compareGetter(params: ValueGetterParams) {
    return this.comparator(params).value;
  }

  getCellColor(params: CellClassParams) {
    return { backgroundColor: this.comparator(params).color };
  }

  getCellColorByDate(params: CellClassParams) {
    let style = {};
    const sDate = moment(params.value);
    if (sDate.isBefore(this.NinetyDaysAgo)) {
      style = { backgroundColor: 'tomato' };
    }
    return style;
  }

  comparator(params: any) {
    const field = params.colDef.headerName || '';
    const fieldV2 = this.fieldMappings[field];

    let valv1 = params.data.v1Data[field];
    let valv2 = params.data.v2Data[fieldV2];

    if (field.endsWith('At')) {
      if (valv1) valv1 = new Date(valv1).toLocaleString('en-us', { timeZone: 'UTC' });
      if (valv2) valv2 = new Date(valv2).toLocaleString('en-us', { timeZone: 'UTC' });
    }

    if (!valv1 && valv2) {
      return { value: valv2, color: 'green' };
    } else if (valv1 && !valv2) {
      return { value: valv1 + ' => [EMPTY]', color: 'tomato' };
    } else if (valv1 && valv2 && valv1 !== valv2) {
      return { value: valv1 + ' => ' + valv2, color: 'yellow' };
    } else {
      return { value: valv1, color: 'white' };
    }
  }

  gridApi: any;
  gridColumnApi: any;
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.columnApi.sizeColumnsToFit();
  }

  private simpleFilterParams: ITextFilterParams = {
    maxNumConditions: 1,
  };
  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    filterParams: this.simpleFilterParams,
    resizable: true,
    flex: 1,
    minWidth: 100,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
    autoHeight: true,
  };

  columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'id',
      valueGetter: (params: any) => {
        return params.data.v1Data['id'];
      },
      cellStyle: this.getCellColor.bind(this),
      cellRenderer: (params: { value: any }) => {
        if (!params.value) {
          return '';
        } else {
          return (
            '<a href="https://gotlivedata.com/datawarehouse/inspect?ref=' +
            params.value +
            '" target=_blank>' +
            params.value +
            '</a>'
          );
        }
      },
    },
    {
      field: 'linkedin',
      valueGetter: (params: any) => {
        return params.data.v1Data['linkedin'];
      },
      cellRenderer: (params: { value: any }) => {
        if (!params.value) {
          return '';
        } else {
          return (
            '<a href="https://www.linkedin.com/in/' +
            params.value +
            '" target=_blank>' +
            params.value +
            '</a>'
          );
        }
      },
      headerTooltip: 'The authoritative LinkedIn ID of this person according to Live Data.',
    },
    {
      headerName: 'importCompany',
      valueGetter: (params: any) => {
        return params.data.v1Data['importCompany'];
      },
    },
    {
      headerName: 'v1 Company',
      valueGetter: (params: any) => {
        return params.data.v1Data['company'];
      },
    },
    {
      headerName: 'v2 Company',
      valueGetter: (params: any) => {
        return params.data.v2Data['current_position.company.name'];
      },
    },
    {
      headerName: 'v1/2 company name agree?',
      valueGetter: (params: any) => {
        return this.compareCompanies(
          params.data.v2Data['current_position.company.name'],
          params.data.v1Data['company']
        );
      },
    },
    {
      headerName: 'v1/2 changed agree?',
      valueGetter: (params: any) => {
        return (
          params.data.v1Data['lastJobChangedAt'] == params.data.v2Data['last_company_changed_at']
        );
      },
    },
    {
      headerName: 'changed v1?',
      valueGetter: (params: any) => {
        return params.data.v1Data['lastJobChangedAt'] ? 'yes' : 'no';
      },
    },
    {
      headerName: 'changed v2?',
      valueGetter: (params: any) => {
        return params.data.v2Data['last_company_changed_at'] ? 'yes' : 'no';
      },
    },
    {
      headerName: 'last success',
      valueGetter: (params: any) => {
        return params.data.dwData['last_success_at'];
      },
      cellStyle: this.getCellColorByDate.bind(this),
    },
    {
      headerName: 'fresh',
      valueGetter: (params: any) => {
        return moment(params.data.dwData['last_success_at']).isBefore(this.NinetyDaysAgo)
          ? 'no'
          : 'yes';
      },
      cellStyle: this.getCellColorByDate.bind(this),
    },
    { headerName: 'Jobs', cellRenderer: JobsTableCellRendererComponent, minWidth: 600 },
  ];

  fieldMappings: any = {
    company: 'current_position.company.name',
    'companyInfo.domain': 'current_position.company.domain',
    'companyInfo.industry': 'current_position.company.industry',
    'companyInfo.linkedin': 'current_position.company.linkedin',
    'companyInfo.location': 'current_position.company.location',
    'companyInfo.size': 'current_position.company.employee_count',
    'contactInfo.email': 'current_position.email',
    'contactInfo.emailStatus': 'current_position.email_status',
    createdAt: 'created_at',
    id: 'id',
    importCompany: 'imported.company',
    importTitle: 'imported.title',
    importTitleDomain: 'imported.derived_domain',
    importTitleLevel: 'imported.derived_level',
    isVerified: 'is_verified',
    jobStartedAt: 'current_position.started_at',
    lastInfoChangedAt: 'last_info_changed_at',
    lastJobChangedAt: 'last_company_changed_at',
    ledgerImportId: 'imported.batch_id',
    linkedin: 'linkedin',
    name: 'name',
    referenceId: 'reference_id',
    title: 'current_position.title',
    titleDomain: 'current_position.function',
    titleLevel: 'current_position.level',
    'imported company': 'importCompany',
  };
}
