import { z } from 'zod';
import { UIFilterGroup, ChartType, PositionStatus } from './filters/filters.component';
import { FilterGroupFiltersInner } from 'ldt-people-api';

// Enums
const PositionStatusEnum = z.nativeEnum(PositionStatus);
const ChartTypeEnum = z.nativeEnum(ChartType);
const TypeEnum = z.enum(['must', 'must_not']);
const MatchTypeEnum = z.enum(['fuzzy', 'exact', 'exists']);
const OperatorEnum = z.enum(['and', 'or']);

// FieldsToChart schema
const FieldsToChartSchema = z.object({
  columnName: z.string(),
  chartType: ChartTypeEnum,
});

// SearchFilter schema
const SearchFilterSchema = z.object({
  type: TypeEnum.optional(),
  field: z.string().optional(),
  string_values: z.array(z.string()).optional(),
  date_from: z.string().optional(),
  date_to: z.string().optional(),
  number_min: z.number().optional(),
  number_max: z.number().optional(),
  boolean_value: z.boolean().optional(),
  match_type: MatchTypeEnum.optional(),
});

// UIFilter schema
const UIFilterSchema = z.object({
  filter: SearchFilterSchema,
});

// UIFilterGroup schema
const UIFilterGroupSchema: z.ZodType<UIFilterGroup> = z.lazy(() =>
  z.object({
    operator: z.enum(['and', 'or']),
    filters: z.array(z.union([UIFilterSchema, UIFilterGroupSchema])),
    isJobsGroup: z.boolean().optional(),
    jobsGroupType: z.enum(['any', 'active', 'ended']).optional(),
    fieldsToChart: z.array(FieldsToChartSchema).optional(),
    id: z.number(),
    name: z.string().optional(),
    positionStatus: PositionStatusEnum.optional(),
  })
);

// QuickBuild schema
const QuickBuildSchema = z.object({
  id: z.number(),
  name: z.string(),
  rootFilterGroup: UIFilterGroupSchema,
});

// AggRequest schema
const AggRequestSchema = z.object({
  id: z.string(),
  field_name: z.string(),
});

// FilterGroupFiltersInner schema
const FilterGroupFiltersInnerSchema: z.ZodType<FilterGroupFiltersInner> = z.lazy(() =>
  z.object({
    type: TypeEnum.optional(),
    field: z.string().optional(),
    string_values: z.array(z.string()).optional(),
    date_from: z.string().optional(),
    date_to: z.string().optional(),
    number_min: z.number().optional(),
    number_max: z.number().optional(),
    boolean_value: z.boolean().optional(),
    match_type: MatchTypeEnum.optional(),
    operator: OperatorEnum.optional(),
    filters: z.array(FilterGroupFiltersInnerSchema).optional(),
    aggs: z.array(AggRequestSchema).optional(),
  })
);

// FilterGroup schema
const FilterGroupSchema = z.object({
  operator: OperatorEnum.optional(),
  filters: z.array(FilterGroupFiltersInnerSchema).optional(),
  aggs: z.array(AggRequestSchema).optional(),
});

// Exporting the schemas
export {
  QuickBuildSchema,
  UIFilterGroupSchema,
  UIFilterSchema,
  SearchFilterSchema,
  FieldsToChartSchema,
  FilterGroupFiltersInnerSchema,
  FilterGroupSchema,
  TypeEnum,
  MatchTypeEnum,
  ChartTypeEnum,
  PositionStatusEnum,
  OperatorEnum,
};
