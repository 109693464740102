<div class="tw-p-6">
  <h2 mat-dialog-title class="tw-text-xl tw-font-semibold tw-mb-4">
    {{ isEdit ? 'Edit' : 'Create' }} Location
  </h2>

  <form [formGroup]="locationForm" (ngSubmit)="onSubmit()" class="tw-space-y-4">
    <mat-dialog-content class="tw-max-h-[70vh]">
      <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
        <!-- Raw Location -->
        <mat-form-field class="tw-w-full">
          <mat-label>Raw Location</mat-label>
          <input matInput formControlName="raw" placeholder="Enter raw location" />
        </mat-form-field>

        <!-- Locality -->
        <mat-form-field class="tw-w-full">
          <mat-label>Locality</mat-label>
          <input matInput formControlName="locality" placeholder="Enter locality" />
        </mat-form-field>

        <!-- Region -->
        <mat-form-field class="tw-w-full">
          <mat-label>Region</mat-label>
          <input matInput formControlName="region" placeholder="Enter region" />
        </mat-form-field>

        <!-- Region Abbreviation -->
        <mat-form-field class="tw-w-full">
          <mat-label>Region Abbreviation</mat-label>
          <input
            matInput
            formControlName="region_abbreviation"
            placeholder="Enter region abbreviation" />
        </mat-form-field>

        <!-- County -->
        <mat-form-field class="tw-w-full">
          <mat-label>County</mat-label>
          <input matInput formControlName="county" placeholder="Enter county" />
        </mat-form-field>

        <!-- Country -->
        <mat-form-field class="tw-w-full">
          <mat-label>Country</mat-label>
          <input matInput formControlName="country" placeholder="Enter country" />
        </mat-form-field>

        <!-- Country Code -->
        <mat-form-field class="tw-w-full">
          <mat-label>Country Code</mat-label>
          <mat-select formControlName="country_code">
            <mat-option *ngFor="let country of countryCodes" [value]="country.code">
              {{ country.name }} - ({{ country.code }})
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Postal Code -->
        <mat-form-field class="tw-w-full">
          <mat-label>Postal Code</mat-label>
          <input matInput formControlName="postal_code" placeholder="Enter postal code" />
        </mat-form-field>

        <!-- MSA -->
        <mat-form-field class="tw-w-full">
          <mat-label>MSA</mat-label>
          <mat-select formControlName="msa">
            <mat-option *ngFor="let msa of msaList" [value]="msa">
              {{ msa }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Latitude -->
        <mat-form-field class="tw-w-full">
          <mat-label>Latitude</mat-label>
          <input matInput formControlName="lat" placeholder="Enter latitude" />
          <mat-error *ngIf="locationForm.get('lat')?.errors?.pattern">
            Please enter a valid latitude
          </mat-error>
        </mat-form-field>

        <!-- Longitude -->
        <mat-form-field class="tw-w-full">
          <mat-label>Longitude</mat-label>
          <input matInput formControlName="lon" placeholder="Enter longitude" />
          <mat-error *ngIf="locationForm.get('lon')?.errors?.pattern">
            Please enter a valid longitude
          </mat-error>
        </mat-form-field>

        <!-- FIPS Code -->
        <mat-form-field class="tw-w-full">
          <mat-label>FIPS Code</mat-label>
          <input matInput formControlName="fips_code" placeholder="Enter FIPS code" />
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end" class="tw-mt-6">
      <button mat-button type="button" (click)="onCancel()" class="tw-mr-2">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="!locationForm.valid">
        {{ isEdit ? 'Save' : 'Create' }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
