import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSortModule, MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as FileSaver from 'file-saver';

import { GrowService } from './grow.service';
import {
  SearchFilter,
  QueryDwRequest,
  SearchPersonsDataRequestData,
  SearchService,
} from 'ldt-dw-reader-service-api';
import * as moment from 'moment';
import { NotificationService } from '../shared/notification-service/notification.service';

export interface ResultData {
  checked: boolean;
  name: string;
  company: string;
  title: string;
  lipid: string;
  tenure_avg: number;
  tenure_current: number;
}

@Component({
  selector: 'app-grow',
  templateUrl: './grow.component.html',
  styleUrls: ['./grow.component.scss'],
})
export class GrowComponent implements OnInit {
  loading: Boolean = false;
  resultsColumns: string[] = [
    'checked',
    'name',
    'title',
    'company',
    'linkedIn',
    'tenure_avg',
    'tenure_current',
  ];
  dataSource: MatTableDataSource<ResultData> = new MatTableDataSource<ResultData>();
  sort: MatSort;
  role: string = '';
  company: string = '';
  industry: string = '';
  tags: string = '';

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  constructor(
    private growService: GrowService,
    private dwService: SearchService,
    private notify: NotificationService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  tenureData: any;
  totalTenure: any = {
    current_total: 0,
    current_count: 0,
    past_total: 0,
    past_count: 0,
  };

  getResults() {
    this.loading = true;
    this.dataSource.data = [];

    this.growService.grow(this.role, this.company, this.industry, this.tags).subscribe({
      next: (r: any) => {
        // Make unique
        // Reverse so that earlier results take precedence and then reverse again
        this.dataSource.data = <Array<ResultData>>(
          [
            ...new Map(r.results.reverse().map((item: ResultData) => [item.lipid, item])).values(),
          ].reverse()
        );
        this.loading = false;

        if (r.results.length == 0) {
          this.notify.error('No results found');
          return;
        }

        const lipids = r.results.map((item: ResultData) => item.lipid).join(',');
        let filters: SearchFilter[] = [];
        filters.push({
          type: SearchFilter.TypeEnum.Must,
          match_type: SearchFilter.MatchTypeEnum.Exact,
          field: 'linkedin',
          string_values: r.results.map((item: ResultData) => item.lipid),
        });

        let searchQuery: SearchPersonsDataRequestData = {
          filters: filters,
          return_fields: ['id', 'linkedin'],
          past_jobs: 0,
          size: 10000,
        };

        this.dwService.searchPersonsData(undefined, searchQuery).subscribe({
          next: (r: any) => {
            this.mbQuery.query.bool.should = r.results.hits.hits.map((item: any) => {
              return {
                match_phrase_prefix: {
                  id: item._source.id,
                },
              };
            });
            const lipidMapping = r.results.hits.hits.reduce((map: any, item: any) => {
              map[item._source.id] = item._source.linkedin;
              return map;
            }, {});

            let body2: QueryDwRequest = {
              query: this.mbQuery,
              index: 'moneyball',
            };
            this.dwService.queryDw(body2).subscribe({
              next: (r: any) => {
                let mb_data = r.hits.hits;
                // map/reduce the data by id and average the tenure data
                let mb_data_map = mb_data.reduce((map: any, item: any) => {
                  const ldp_s = item._source.id.split('#').slice(0, 1);
                  const ldp = lipidMapping[ldp_s];
                  if (!map[ldp]) {
                    map[ldp] = {
                      current_total: !item._source.tenure_months
                        ? moment().diff(moment(item._source.started_at), 'months')
                        : 0,
                      current_count: !item._source.tenure_months ? 1 : 0,
                      past_total: item._source.tenure_months ? item._source.tenure_months : 0,
                      past_count: item._source.tenure_months ? 1 : 0,
                    };
                  } else {
                    map[ldp].current_total += !item._source.tenure_months
                      ? moment().diff(moment(item._source.started_at), 'months')
                      : 0;
                    map[ldp].current_count += !item._source.tenure_months ? 1 : 0;
                    map[ldp].past_total += item._source.tenure_months
                      ? item._source.tenure_months
                      : 0;
                    map[ldp].past_count += item._source.tenure_months ? 1 : 0;
                  }
                  this.totalTenure.current_total += !item._source.tenure_months
                    ? moment().diff(moment(item._source.started_at), 'months')
                    : 0;
                  this.totalTenure.current_count += !item._source.tenure_months ? 1 : 0;
                  this.totalTenure.past_total += item._source.tenure_months
                    ? item._source.tenure_months
                    : 0;
                  this.totalTenure.past_count += item._source.tenure_months ? 1 : 0;
                  return map;
                }, {});

                this.tenureData = mb_data_map;

                this.dataSource.data.forEach((item: ResultData) => {
                  if (mb_data_map[item.lipid]) {
                    item.tenure_current =
                      mb_data_map[item.lipid].current_total / mb_data_map[item.lipid].current_count;
                    item.tenure_avg =
                      mb_data_map[item.lipid].past_total / mb_data_map[item.lipid].past_count;
                  }
                });
              },
              error: (e: any) => {
                console.log(e);
                this.notify.error('Error getting data from MB service');
              },
            });
          },
          error: (e: any) => {
            console.log(e);
            this.notify.error('Error finding people in DW service');
          },
        });
      },
      error: (e: any) => {
        console.log(e);
        this.notify.error('Error getting data from Grow service');
        this.loading = false;
      },
    });
  }

  downloadResults(): void {
    const toDownload = this.dataSource.data.filter((e) => e.checked);
    const filename = `${this.role}@${this.company}.csv`.replace(' ', '-');

    const csvString = [
      ['Name', 'Title', 'Company', 'LinkedIn'],
      ...toDownload.map((item) => [
        `"${item.name}"`,
        `"${item.title}"`,
        `"${item.company}"`,
        `https://linkedin.com/in/${item.lipid}`,
      ]),
    ]
      .map((e) => e.join(','))
      .join('\n');

    const data: Blob = new Blob([csvString], {
      type: 'text/csv;charset=utf-8',
    });

    FileSaver.saveAs(data, filename);
  }

  toggleCheckAll(event: any) {
    this.dataSource.data.forEach((e) => {
      e.checked = event.checked;
    });
  }

  mbQuery = {
    query: {
      bool: {
        should: [],
      },
    },
    _source: ['id', 'tenure_months', 'started_at'],
    size: 10000,
  };
}
