import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MSAList } from './msa-list';
import { CountryCodes } from './country-codes';

export interface ResolverLocationValue {
  raw: string;
  locality: string;
  region: string;
  region_abbreviation: string;
  county: string;
  country: string;
  country_code: string;
  postal_code: string;
  msa: string;
  lat: string;
  lon: string;
  fips_code: string;
}

@Component({
  selector: 'app-location-value-dialog',
  templateUrl: './location-value-dialog.component.html',
  styleUrls: ['./location-value-dialog.component.scss'],
})
export class ResolverLocationValueDialogComponent {
  locationForm: FormGroup;
  isEdit = false;
  msaList = MSAList;
  countryCodes = CountryCodes;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ResolverLocationValueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResolverLocationValue | null
  ) {
    this.isEdit = !!data;
    this.locationForm = this.fb.group({
      raw: ['', Validators.required],
      locality: [''],
      region: [''],
      region_abbreviation: [''],
      county: [''],
      country: [''],
      country_code: ['', [Validators.maxLength(2)]],
      postal_code: [''],
      msa: [''],
      lat: ['', [Validators.pattern(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}$/)]],
      lon: ['', [Validators.pattern(/^-?((1?[0-7]?|[1-9]?)[0-9]|180)\.{1}\d{1,6}$/)]],
      fips_code: [''],
    });

    if (data) {
      this.locationForm.patchValue(data);
    }
  }

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.locationForm.valid) {
      this.dialogRef.close(this.locationForm.value);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
