import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { NotificationService } from './notification-service/notification.service';
import { FilterPipe } from './pipes/filter.pipe';
import { CustomStepperComponent } from './stepper/stepper.component';
import { TestStatusBadgeComponent } from './test-status-badge/test-status-badge.component';
import { JobFunctionDropdownComponent } from './job-function-dropdown/job-function-dropdown.component';
import { BadgeComponent } from './badge/badge.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DateRangePickerCustomRangePanelComponent } from './date-range-picker/custom-range-panel/custom-range-panel.component';
import { SavedListsComponent } from './saved-lists/saved-lists.component';
import { JobLevelDropdownComponent } from './job-level-dropdown/job-level-dropdown.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    FilterPipe,
    CustomStepperComponent,
    TestStatusBadgeComponent,
    JobFunctionDropdownComponent,
    JobLevelDropdownComponent,
    BadgeComponent,
    DateRangePickerComponent,
    DateRangePickerCustomRangePanelComponent,
    SavedListsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  exports: [
    ReactiveFormsModule,
    FilterPipe,
    CustomStepperComponent,
    TestStatusBadgeComponent,
    JobFunctionDropdownComponent,
    JobLevelDropdownComponent,
    BadgeComponent,
    MatIconModule,
    DateRangePickerComponent,
  ],
  providers: [NotificationService],
})
export class SharedModule {}
