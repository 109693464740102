export const MSAList: string[] = [
  'Abilene, TX',
  'Akron, OH',
  'Albany, GA',
  'Albany, OR',
  'Albany-Schenectady-Troy, NY',
  'Albuquerque, NM',
  'Alexandria, LA',
  'Allentown-Bethlehem-Easton, PA-NJ',
  'Altoona, PA',
  'Amarillo, TX',
  'Ames, IA',
  'Amherst Town-Northampton, MA',
  'Anchorage, AK',
  'Ann Arbor, MI',
  'Anniston-Oxford, AL',
  'Appleton, WI',
  'Asheville, NC',
  'Athens-Clarke County, GA',
  'Atlanta-Sandy Springs-Roswell, GA',
  'Atlantic City-Hammonton, NJ',
  'Auburn-Opelika, AL',
  'Augusta-Richmond County, GA-SC',
  'Austin-Round Rock-San Marcos, TX',
  'Bakersfield-Delano, CA',
  'Baltimore-Columbia-Towson, MD',
  'Bangor, ME',
  'Barnstable Town, MA',
  'Baton Rouge, LA',
  'Battle Creek, MI',
  'Bay City, MI',
  'Beaumont-Port Arthur, TX',
  'Beckley, WV',
  'Bellingham, WA',
  'Bend, OR',
  'Billings, MT',
  'Binghamton, NY',
  'Birmingham, AL',
  'Bismarck, ND',
  'Blacksburg-Christiansburg-Radford, VA',
  'Bloomington, IL',
  'Bloomington, IN',
  'Boise City, ID',
  'Boston-Cambridge-Newton, MA-NH',
  'Boulder, CO',
  'Bowling Green, KY',
  'Bozeman, MT',
  'Bremerton-Silverdale-Port Orchard, WA',
  'Bridgeport-Stamford-Danbury, CT',
  'Brownsville-Harlingen, TX',
  'Brunswick-St. Simons, GA',
  'Buffalo-Cheektowaga, NY',
  'Burlington, NC',
  'Burlington-South Burlington, VT',
  'Canton-Massillon, OH',
  'Cape Coral-Fort Myers, FL',
  'Cape Girardeau, MO-IL',
  'Carson City, NV',
  'Casper, WY',
  'Cedar Rapids, IA',
  'Chambersburg, PA',
  'Champaign-Urbana, IL',
  'Charleston, WV',
  'Charleston-North Charleston, SC',
  'Charlotte-Concord-Gastonia, NC-SC',
  'Charlottesville, VA',
  'Chattanooga, TN-GA',
  'Cheyenne, WY',
  'Chicago-Naperville-Elgin, IL-IN',
  'Chico, CA',
  'Cincinnati, OH-KY-IN',
  'Clarksville, TN-KY',
  'Cleveland, OH',
  'Cleveland, TN',
  "Coeur d'Alene, ID",
  'College Station-Bryan, TX',
  'Colorado Springs, CO',
  'Columbia, MO',
  'Columbia, SC',
  'Columbus, GA-AL',
  'Columbus, IN',
  'Columbus, OH',
  'Corpus Christi, TX',
  'Corvallis, OR',
  'Crestview-Fort Walton Beach-Destin, FL',
  'Dallas-Fort Worth-Arlington, TX',
  'Dalton, GA',
  'Daphne-Fairhope-Foley, AL',
  'Davenport-Moline-Rock Island, IA-IL',
  'Dayton-Kettering-Beavercreek, OH',
  'Decatur, AL',
  'Decatur, IL',
  'Deltona-Daytona Beach-Ormond Beach, FL',
  'Denver-Aurora-Centennial, CO',
  'Des Moines-West Des Moines, IA',
  'Detroit-Warren-Dearborn, MI',
  'Dothan, AL',
  'Dover, DE',
  'Dubuque, IA',
  'Duluth, MN-WI',
  'Durham-Chapel Hill, NC',
  'Eagle Pass, TX',
  'Eau Claire, WI',
  'El Centro, CA',
  'Elizabethtown, KY',
  'Elkhart-Goshen, IN',
  'Elmira, NY',
  'El Paso, TX',
  'Enid, OK',
  'Erie, PA',
  'Eugene-Springfield, OR',
  'Evansville, IN',
  'Fairbanks-College, AK',
  'Fargo, ND-MN',
  'Farmington, NM',
  'Fayetteville, NC',
  'Fayetteville-Springdale-Rogers, AR',
  'Flagstaff, AZ',
  'Flint, MI',
  'Florence, SC',
  'Florence-Muscle Shoals, AL',
  'Fond du Lac, WI',
  'Fort Collins-Loveland, CO',
  'Fort Smith, AR-OK',
  'Fort Wayne, IN',
  'Fresno, CA',
  'Gadsden, AL',
  'Gainesville, FL',
  'Gainesville, GA',
  'Gettysburg, PA',
  'Glens Falls, NY',
  'Goldsboro, NC',
  'Grand Forks, ND-MN',
  'Grand Island, NE',
  'Grand Junction, CO',
  'Grand Rapids-Wyoming-Kentwood, MI',
  'Grants Pass, OR',
  'Great Falls, MT',
  'Greeley, CO',
  'Green Bay, WI',
  'Greensboro-High Point, NC',
  'Greenville, NC',
  'Greenville-Anderson-Greer, SC',
  'Gulfport-Biloxi, MS',
  'Hagerstown-Martinsburg, MD-WV',
  'Hammond, LA',
  'Hanford-Corcoran, CA',
  'Harrisburg-Carlisle, PA',
  'Harrisonburg, VA',
  'Hartford-West Hartford-East Hartford, CT',
  'Hattiesburg, MS',
  'Helena, MT',
  'Hickory-Lenoir-Morganton, NC',
  'Hilton Head Island-Bluffton-Port Royal, SC',
  'Hinesville, GA',
  'Homosassa Springs, FL',
  'Hot Springs, AR',
  'Houma-Bayou Cane-Thibodaux, LA',
  'Houston-Pasadena-The Woodlands, TX',
  'Huntington-Ashland, WV-KY-OH',
  'Huntsville, AL',
  'Idaho Falls, ID',
  'Indianapolis-Carmel-Greenwood, IN',
  'Iowa City, IA',
  'Ithaca, NY',
  'Jackson, MI',
  'Jackson, MS',
  'Jackson, TN',
  'Jacksonville, FL',
  'Jacksonville, NC',
  'Janesville-Beloit, WI',
  'Jefferson City, MO',
  'Johnson City, TN',
  'Johnstown, PA',
  'Jonesboro, AR',
  'Joplin, MO-KS',
  'Kahului-Wailuku, HI',
  'Kalamazoo-Portage, MI',
  'Kankakee, IL',
  'Kansas City, MO-KS',
  'Kennewick-Richland, WA',
  'Kenosha, WI',
  'Killeen-Temple, TX',
  'Kingsport-Bristol, TN-VA',
  'Kingston, NY',
  'Kiryas Joel-Poughkeepsie-Newburgh, NY',
  'Knoxville, TN',
  'Kokomo, IN',
  'La Crosse-Onalaska, WI-MN',
  'Lafayette, LA',
  'Lafayette-West Lafayette, IN',
  'Lake Charles, LA',
  'Lake Havasu City-Kingman, AZ',
  'Lakeland-Winter Haven, FL',
  'Lancaster, PA',
  'Lansing-East Lansing, MI',
  'Laredo, TX',
  'Las Cruces, NM',
  'Las Vegas-Henderson-North Las Vegas, NV',
  'Lawrence, KS',
  'Lawton, OK',
  'Lebanon, PA',
  'Lewiston, ID-WA',
  'Lewiston-Auburn, ME',
  'Lexington-Fayette, KY',
  'Lexington Park, MD',
  'Lima, OH',
  'Lincoln, NE',
  'Little Rock-North Little Rock-Conway, AR',
  'Logan, UT-ID',
  'Longview, TX',
  'Longview-Kelso, WA',
  'Los Angeles-Long Beach-Anaheim, CA',
  'Louisville/Jefferson County, KY-IN',
  'Lubbock, TX',
  'Lynchburg, VA',
  'Macon-Bibb County, GA',
  'Madison, WI',
  'Manchester-Nashua, NH',
  'Manhattan, KS',
  'Mankato, MN',
  'Mansfield, OH',
  'McAllen-Edinburg-Mission, TX',
  'Medford, OR',
  'Memphis, TN-MS-AR',
  'Merced, CA',
  'Miami-Fort Lauderdale-West Palm Beach, FL',
  'Michigan City-La Porte, IN',
  'Midland, MI',
  'Midland, TX',
  'Milwaukee-Waukesha, WI',
  'Minneapolis-St. Paul-Bloomington, MN-WI',
  'Minot, ND',
  'Missoula, MT',
  'Mobile, AL',
  'Modesto, CA',
  'Monroe, LA',
  'Monroe, MI',
  'Montgomery, AL',
  'Morgantown, WV',
  'Morristown, TN',
  'Mount Vernon-Anacortes, WA',
  'Muncie, IN',
  'Muskegon-Norton Shores, MI',
  'Myrtle Beach-Conway-North Myrtle Beach, SC',
  'Napa, CA',
  'Naples-Marco Island, FL',
  'Nashville-Davidson--Murfreesboro--Franklin, TN',
  'New Haven, CT',
  'New Orleans-Metairie, LA',
  'New York-Newark-Jersey City, NY-NJ',
  'Niles, MI',
  'North Port-Bradenton-Sarasota, FL',
  'Norwich-New London-Willimantic, CT',
  'Ocala, FL',
  'Odessa, TX',
  'Ogden, UT',
  'Oklahoma City, OK',
  'Olympia-Lacey-Tumwater, WA',
  'Omaha, NE-IA',
  'Orlando-Kissimmee-Sanford, FL',
  'Oshkosh-Neenah, WI',
  'Owensboro, KY',
  'Oxnard-Thousand Oaks-Ventura, CA',
  'Paducah, KY-IL',
  'Palm Bay-Melbourne-Titusville, FL',
  'Panama City-Panama City Beach, FL',
  'Parkersburg-Vienna, WV',
  'Pensacola-Ferry Pass-Brent, FL',
  'Peoria, IL',
  'Philadelphia-Camden-Wilmington, PA-NJ-DE-MD',
  'Phoenix-Mesa-Chandler, AZ',
  'Pinehurst-Southern Pines, NC',
  'Pittsburgh, PA',
  'Pittsfield, MA',
  'Pocatello, ID',
  'Portland-South Portland, ME',
  'Portland-Vancouver-Hillsboro, OR-WA',
  'Port St. Lucie, FL',
  'Prescott Valley-Prescott, AZ',
  'Providence-Warwick, RI-MA',
  'Provo-Orem-Lehi, UT',
  'Pueblo, CO',
  'Punta Gorda, FL',
  'Racine-Mount Pleasant, WI',
  'Raleigh-Cary, NC',
  'Rapid City, SD',
  'Reading, PA',
  'Redding, CA',
  'Reno, NV',
  'Richmond, VA',
  'Riverside-San Bernardino-Ontario, CA',
  'Roanoke, VA',
  'Rochester, MN',
  'Rochester, NY',
  'Rockford, IL',
  'Rocky Mount, NC',
  'Rome, GA',
  'Sacramento-Roseville-Folsom, CA',
  'Saginaw, MI',
  'St. Cloud, MN',
  'St. George, UT',
  'St. Joseph, MO-KS',
  'St. Louis, MO-IL',
  'Salem, OR',
  'Salinas, CA',
  'Salisbury, MD',
  'Salt Lake City-Murray, UT',
  'San Angelo, TX',
  'San Antonio-New Braunfels, TX',
  'San Diego-Chula Vista-Carlsbad, CA',
  'Sandusky, OH',
  'San Francisco-Oakland-Fremont, CA',
  'San Jose-Sunnyvale-Santa Clara, CA',
  'San Luis Obispo-Paso Robles, CA',
  'Santa Cruz-Watsonville, CA',
  'Santa Fe, NM',
  'Santa Maria-Santa Barbara, CA',
  'Santa Rosa-Petaluma, CA',
  'Savannah, GA',
  'Scranton--Wilkes-Barre, PA',
  'Seattle-Tacoma-Bellevue, WA',
  'Sebastian-Vero Beach-West Vero Corridor, FL',
  'Sebring, FL',
  'Sheboygan, WI',
  'Sherman-Denison, TX',
  'Shreveport-Bossier City, LA',
  'Sierra Vista-Douglas, AZ',
  'Sioux City, IA-NE-SD',
  'Sioux Falls, SD-MN',
  'Slidell-Mandeville-Covington, LA',
  'South Bend-Mishawaka, IN-MI',
  'Spartanburg, SC',
  'Spokane-Spokane Valley, WA',
  'Springfield, IL',
  'Springfield, MA',
  'Springfield, MO',
  'Springfield, OH',
  'State College, PA',
  'Staunton-Stuarts Draft, VA',
  'Stockton-Lodi, CA',
  'Sumter, SC',
  'Syracuse, NY',
  'Tallahassee, FL',
  'Tampa-St. Petersburg-Clearwater, FL',
  'Terre Haute, IN',
  'Texarkana, TX-AR',
  'Toledo, OH',
  'Topeka, KS',
  'Traverse City, MI',
  'Trenton-Princeton, NJ',
  'Tucson, AZ',
  'Tulsa, OK',
  'Tuscaloosa, AL',
  'Twin Falls, ID',
  'Tyler, TX',
  'Urban Honolulu, HI',
  'Utica-Rome, NY',
  'Valdosta, GA',
  'Vallejo, CA',
  'Victoria, TX',
  'Vineland, NJ',
  'Virginia Beach-Chesapeake-Norfolk, VA-NC',
  'Visalia, CA',
  'Waco, TX',
  'Walla Walla, WA',
  'Warner Robins, GA',
  'Washington-Arlington-Alexandria, DC-VA-MD-WV',
  'Waterbury-Shelton, CT',
  'Waterloo-Cedar Falls, IA',
  'Watertown-Fort Drum, NY',
  'Wausau, WI',
  'Weirton-Steubenville, WV-OH',
  'Wenatchee-East Wenatchee, WA',
  'Wheeling, WV-OH',
  'Wichita, KS',
  'Wichita Falls, TX',
  'Wildwood-The Villages, FL',
  'Williamsport, PA',
  'Wilmington, NC',
  'Winchester, VA-WV',
  'Winston-Salem, NC',
  'Worcester, MA',
  'Yakima, WA',
  'York-Hanover, PA',
  'Youngstown-Warren, OH',
  'Yuba City, CA',
  'Yuma, AZ',
];
